<template>
  <div class="pa-5">
    <v-card
        class="mx-auto pa-3"
    >
      <v-card-title style="display: flex; flex-flow: row nowrap; align-items: flex-start; gap: 5%; white-space: nowrap">
        <h2 v-t="'Themes.ThemesManagement'"></h2>
        <v-spacer></v-spacer>
        <v-row style="display: flex; flex-flow: row nowrap; gap: 1%">
          <v-text-field style="flex: 1 3 80px;" :min="1" type="number" v-model="nbShown" :label="$t('Themes.ThemesPerPage')" outlined></v-text-field>
          <v-text-field
            style="flex: 3 1 80px;"
            v-model="search"
            :label="$t('General.Search')"
            outlined
          ></v-text-field>
          <v-btn color='primary' @click="$router.push('Themes/new')">
            <v-icon left>
              mdi-plus
            </v-icon>
            {{ $t('Themes.AddTheme') }}
          </v-btn>
        </v-row>
      </v-card-title>
      <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th v-t="'General.Preview'" class='text-center'></th>
            <th v-t="'General.PublishingStatus'" class='text-center'></th>
            <th v-t="'Themes.ThemeName'" class='text-center'></th>
            <th v-t="'Themes.Reference'" class='text-center'></th>
            <th v-t="'General.Sections'" class='text-center'></th>
            <th v-t="'General.Actions'" class='text-center'></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for='theme in shownThemes' :key='theme.id' :id="theme.id" :ref="theme.id">
            <td style="cursor: pointer" @click="showModal(theme.id)">
              <div v-if="theme.themePreview">
                <img style="pointer-events: none; height: 200px;" :src="theme.s3ImageSrc">
                <v-dialog v-model="showImage[theme.id]" width="500px">
                  <v-card>
                    <img style="height: 500px" :src="theme.s3ImageSrc">
                  </v-card>
                </v-dialog></div>
            </td>
            <td>
              <div style="display: flex; justify-content: center;">
                <v-switch
                    @change="modifyTheme(theme)"
                    v-model="theme.published"
                ></v-switch>
              </div>
            </td>
            <td>{{ theme.name }}</td>
            <td>{{ theme.sku }}</td>
            <td>
              <v-chip label v-if="theme.sectionListNames.length === 1">
                {{ theme.sectionListNames[0] }}
              </v-chip>
              <v-menu transition="slide-y-transition" open-on-hover offset-y v-else>
                <template v-slot:activator="{ on, attrs }">
                  <v-chip label v-bind="attrs" v-on="on">{{ theme.sectionListNames.length }} rubriques</v-chip>
                </template>
                <div style="display: flex; flex-direction: column; background-color: white">
                  <v-chip style="justify-content: center" class="ma-1" color="#e0e0e0" label v-for="sectionName in theme.sectionListNames" :key="sectionName+theme.id">
                    {{ sectionName }}
                  </v-chip>
                </div>
              </v-menu>
            </td>
            <td>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="black"
                      icon
                      dark
                      @click="configTheme(theme)"
                  >
                    <v-icon>mdi-draw</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.EditDesign'"></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="secondary"
                      icon
                      dark
                      @click="$router.push('Themes/' + theme.id)"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Edit'"></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="red"
                      icon
                      @click='deleteTheme(theme)'
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.Delete'"></span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                      v-on="on"
                      color="black"
                      icon
                      @click='downloadThemeSVG(theme)'
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span v-t="'General.DownloadSVGFormat'"></span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-pagination v-model="currentPage" total-visible="7" :length="pages"></v-pagination>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { fabric } from 'fabric'
import WebFont from 'webfontloader'

export default {
  name: 'Themes',
  data () {
    return {
      themes: [],
      currentThemes: [],
      showImage: [],
      sections: [],
      currentPage: 1,
      nbShown: 20,
      loading: false,
      search: '',
      exportCanvas: '',
      currentTheme: '',
      tags: []
    }
  },
  async created () {
    await this.getTags()
    await this.getThemes()
    await this.getSections()
    this.themes.forEach((theme) => {
      theme['sectionListNames'] = []
      theme['tagListNames'] = []
      this.sections.forEach((section) => {
        if (theme.sectionList.find((sectionId) => sectionId === section.id)) {
          theme['sectionListNames'].push(section.name)
        }
      })
      theme.tagList.forEach((tagId) => {
        const tag = this.tags.find((tag) => tag.id === tagId)
        if (tag) {
          theme['tagListNames'].push(tag.name)
        }
      })
    })
    this.currentThemes = this.themes
    if (this.shownThemes && this.shownThemes.length > 0) {
      await this.downloadAndSetImage(this.shownThemes)
    }
    var lastSelectedTheme = this.$store.getters['GET_LAST_SELECTED_THEME']()
    if (lastSelectedTheme) {
      var index = this.themes.indexOf(this.themes.find((theme) => { return theme.id === lastSelectedTheme }))
      this.currentPage = Math.floor(index / this.nbShown) + 1
      this.$nextTick(() => { this.$refs[lastSelectedTheme][0].scrollIntoView(); window.scrollBy(0, -128) })
      this.$store.commit('SET_LAST_SELECTED_THEME', null)
    }
  },
  computed: {
    shownThemes () {
      return this.currentThemes.slice((this.currentPage - 1) * this.nbShown, this.currentPage * this.nbShown)
    },
    pages () {
      return Math.floor(this.currentThemes.length / this.nbShown) + (this.currentThemes.length % this.nbShown === 0 ? 0 : 1)
    }
  },
  watch: {
    currentPage () {
      this.downloadAndSetImage(this.shownThemes)
    },
    nbShown (val) {
      this.nbShown = val <= 0 ? 1 : val
      this.downloadAndSetImage(this.shownThemes)
    },
    search: function (val) {
      this.searchThemes(val)
      this.downloadAndSetImage(this.shownThemes)
    }
  },
  methods: {
    async fetchThemeJsonFromS3 (themeId) {
      try {
        await this.$store.dispatch('getPresignedUrlForSVG', themeId)
        const presignedUrl = this.$store.getters.GET_PRESIGNED_URL()
        const response = await fetch(presignedUrl.file)
        if (!response.ok) {
          throw new Error(`Failed to retrieve theme JSON, status: ${response.status}`)
        }
        return await response.json()
      } catch (error) {
        this.$emit('notify', {
          color: 'red',
          text: 'Erreur lors de la récupération du JSON du thème: ' + error.message
        })
        return null
      }
    },
    searchThemes (val) {
      this.currentPage = 1
      val = this.normalizeString(val)

      this.currentThemes = val === '' ? this.themes : this.themes.filter(theme => this.themeParametersSearch(theme, val))
    },
    normalizeString (str) {
      return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim()
    },
    themeParametersSearch (theme, val) {
      const themeName = this.normalizeString(theme.name)
      const themeSKU = theme.sku.toLowerCase()
      const themeSections = theme.sectionListNames || []
      const themeTags = theme.tagListNames || []
      const searchString = themeName + ' ' + themeSKU + ' '

      return searchString.includes(val) || themeSections.some(section => this.normalizeString(section).includes(val)) || themeTags.some(tag => this.normalizeString(tag).includes(val))
    },
    showModal (themeid) {
      Vue.set(this.showImage, themeid, true)
    },
    configTheme (theme) {
      this.$router.push('/ThemeCreator/' + theme.id)
    },
    deleteTheme (theme) {
      this.$store.dispatch('deleteTheme', theme.id).then(() => {
        this.$emit('notify', {
          color: 'green',
          text: this.$t('Messages.ThemeDeleted')
        })
        const index = this.themes.findIndex(t => t.id === theme.id)
        if (index !== -1) {
          this.themes.splice(index, 1)
        }
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    async downloadThemeSVG (theme) {
      this.exportCanvas = new fabric.Canvas()
      this.exportCanvas.setWidth(1024)
      this.exportCanvas.setHeight(1024)
      const parsedJson = await this.fetchThemeJsonFromS3(theme.id)
      if (!parsedJson) return
      theme.themeJson = parsedJson
      if (parsedJson && parsedJson.fonts.length > 0) {
        const fontsUsed = []
        parsedJson.fonts.forEach((font) => {
          fontsUsed.push(font.split('family=')[1])
        })
        WebFont.load({
          google: {
            families: fontsUsed
          },
          active: () => {
            this.loadThemeJSON(theme)
          }
        })
      } else if (parsedJson) {
        this.loadThemeJSON(theme)
      }
    },
    loadThemeJSON (theme) {
      this.exportCanvas.loadFromJSON(theme.themeJson, () => {
        const scale = this.exportCanvas.getHeight() / 256
        const objects = this.exportCanvas.getObjects()
        for (const i in objects) {
          objects[i].scaleX *= scale
          objects[i].scaleY *= scale
          objects[i].left *= scale
          objects[i].top *= scale
          objects[i].setCoords()

          if (objects[i].isMask && objects[i].customClipPathId) {
            this.exportCanvas.getObjects().forEach((objToClip) => {
              if (!objToClip.isMask && objToClip.customClipPathId === objects[i].customClipPathId) {
                objToClip.clipPath = objects[i]
              }
            })
          }
          this.exportCanvas.renderAll()
        }
        this.downloadSvg()
      })
    },
    downloadSvg () {
      this.$store.dispatch('clearSelectedFonts')
      const objects = this.exportCanvas.getObjects()
      objects.forEach((obj) => {
        if (obj.fontFamily) {
          this.$store.dispatch('addSelectedFont', obj.fontFamily)
        }
      })

      let svg = this.exportCanvas.toSVG()
      const selectedFonts = this.$store.getters['GET_SELECTED_FONTS']()
      if (selectedFonts.length > 0) {
        var svgtest = svg.split('<defs>')
        selectedFonts.forEach((selectedFont) => {
          const url = 'https://fonts.googleapis.com/css?family=' + selectedFont.replaceAll(' ', '+')
          svgtest[1] = '@import url(' + url + ');' + svgtest[1]
        })
        svgtest[1] = '<defs><style>' + svgtest[1]
        svgtest = svgtest.join('')
        var svgteststyle = svgtest.split('</defs>')
        svgteststyle[1] = '</style></defs>' + svgteststyle[1]
        svg = svgteststyle.join('')
      }
      const blob = new Blob([svg], { type: 'image/svg+xml' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      a.download = 'canvas.svg'
      a.click()
      URL.revokeObjectURL(a.href)
    },
    async getSections () {
      return this.$store.dispatch('getAllSections').then(() => {
        this.sections = this.$store.getters['GET_SECTIONS']()
      })
    },
    async getThemes () {
      return this.$store.dispatch('getAllThemes').then(() => {
        this.themes = this.$store.getters['GET_THEMES']()
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    async getTags () {
      try {
        await this.$store.dispatch('getAllTags')
        this.tags = this.$store.state.tags
      } catch (error) {
        console.error('An error occurred while retrieving tags:', error)
      }
    },
    async modifyTheme (theme) {
      await this.$store.dispatch('getTheme', theme.id)
      const themeToUpdate = this.$store.state.currentTheme
      themeToUpdate.published = theme.published
      this.$store.dispatch('editTheme', themeToUpdate).then(() => {
        this.$store.commit('SET_CURRENT_THEME', {})
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    async downloadAndSetImage (themes) {
      for (const theme of themes) {
        if (theme.themePreview) {
          try {
            theme.s3ImageSrc = theme.themePreview
          } catch (error) {
            console.error("Erreur lors du téléchargement de l'image:", error)
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
